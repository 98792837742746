import React, { FC, useEffect, useState } from "react";
import Button from "../../components/Button";
import Member from "../../models/Member";
import Payment from "../../models/Payment";
import { formatDate } from "../../utils";
import moment from "moment";
import cx from "classnames";
import ACHForm from "../../components/Forms/ACHForm";
import CreditForm from "../../components/Forms/CreditForm";

type PaymentInformationProps = {
  member: Member;
  payment: Payment;
  setPayment: React.Dispatch<React.SetStateAction<Payment>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const PaymentInformation: FC<PaymentInformationProps> = ({
  member,
  payment,
  setPayment,
  setStep,
}: PaymentInformationProps) => {
  const [paymentType, setPaymentType] = useState("ACH");
  const currentDate = moment();

  useEffect(() => {
    if (paymentType === "ACH") {
      setPayment({
        ...payment,
        ccExpYear: undefined,
        ccExpMonth: undefined,
        ccNumber: undefined,
        ccSecurityCode: undefined,
        paymentType: paymentType,
      });
    } else {
      // Type CC
      setPayment({
        ...payment,
        achAccount: undefined,
        achBankName: undefined,
        achRouting: undefined,
        achType: undefined,
        paymentType: paymentType,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType]);

  return (
    <div
      className={cx(
        "flex w-full flex-col",
        "min-h-[70vh] px-2 py-2",
        "xl:pt-17"
      )}
    >
      <div className={"page-header-container"}>
        <h1 className="page-header-text">Payment Information</h1>
      </div>

      <div
        className={cx(
          "flex w-full flex-col",
          "items-center justify-center gap-1"
        )}
      >
        <div
          className={cx(
            "flex flex-col",
            "rounded-3xl border-4 border-black bg-white",
            "p-3 xl:p-9"
          )}
        >
          <div
            className={cx(
              "ml-3 flex flex-col",
              "font-montserrat text-navyBlue",
              "xl:max-h-[690px] xl:max-w-5xl"
            )}
          >
            <div
              className={cx(
                "flex flex-col items-center gap-2",
                "xl:flex-row xl:gap-7"
              )}
            >
              <div className="text-center text-xl font-bold text-navyBlue xl:text-left">
                Please select a payment method
              </div>
              <div>
                <fieldset className="flex flex-col gap-4 md:flex-row">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="method"
                      value="ACH"
                      className="h-[25px] w-[25px] accent-lightBlue checked:bg-lightBlue"
                      checked={paymentType === "ACH"}
                      onChange={(event) => {
                        setPaymentType(event?.target?.value);
                      }}
                    />
                    <span className="pl-2 font-bold text-lightBlue">
                      ACH (Preferred)
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="method"
                      value="CC"
                      className="h-[25px] w-[25px] accent-lightBlue checked:bg-lightBlue"
                      checked={paymentType === "CC"}
                      onChange={(event) => {
                        setPaymentType(event?.target?.value);
                      }}
                    />
                    <span
                      className={`pl-2 ${
                        paymentType === "CC"
                          ? "text-navyBlue"
                          : "text-[#4B4B4B]"
                      }`}
                    >
                      Credit Card
                    </span>
                  </label>
                </fieldset>
              </div>
            </div>

            {paymentType === "ACH" && (
              <>
                <ACHForm
                  payment={payment}
                  setPayment={setPayment}
                  setStep={setStep}
                />
                <div className="flex flex-row font-normal text-black">
                  <img
                    src="/lock.png"
                    alt="lock"
                    className="mt-1 h-[20px] w-fit"
                  />
                  <div className="ml-4 mt-1">
                    {" "}
                    Your Account information is protected using industry
                    standard SSL Encryption technology.
                  </div>
                </div>
                <div
                  className={cx(
                    "flex flex-col",
                    "mb-[37px] max-h-[199px] max-w-[376px]"
                  )}
                >
                  <div className="pb-[10px] text-xl font-bold text-celadonBlue">
                    Example check
                  </div>
                  <img src="/exampleCheck.png" alt="example check" />
                </div>
              </>
            )}

            {paymentType === "CC" && (
              <>
                <CreditForm
                  setPayment={setPayment}
                  setStep={setStep}
                  payment={payment}
                />
                <div className="flex flex-row font-normal text-black">
                  <img
                    src="/lock.png"
                    alt="lock"
                    className="mt-1 h-[20px] w-fit"
                  />
                  <div className="ml-4 mt-1">
                    {" "}
                    Your Account information is protected using industry
                    standard SSL Encryption technology.
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
