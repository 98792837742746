/* eslint-disable indent */
import React, { FC, useState, useEffect } from "react";
import Agent from "../../models/Agent";
import cx from "classnames";
import Button from "../Button";

type WizardProgressBarProps = {
  step: number;
  agent?: Agent;
  purchaseState?: boolean;
  // stepsWithDescription: string[];
  setStep?: React.Dispatch<React.SetStateAction<number>>;
};

const ProgressBar: FC<WizardProgressBarProps> = ({
  step,
  agent,
  purchaseState = false,
  // stepsWithDescription,
  setStep,
}: WizardProgressBarProps) => {
  // const [percentage, setPercentage] = useState(0);

  // useEffect(() => {
  //   const value = Math.round((step * 100) / stepsWithDescription.length);
  //   setPercentage(value);
  // }, [step, stepsWithDescription]);

  const [tempAgentId, setTempAgentId] = useState<boolean>(false);

  // TODO: Find a way to get agent id not from url, if keeping this add checks. Temp code for now
  const getAgentId = () => {
    const getUrl = window.location.href;
    setTempAgentId(getUrl.includes("660555"));
  };

  useEffect(() => {
    getAgentId();
  });

  return (
    <div
      className={cx(
        "flex h-[84px] w-full flex-col items-center justify-between",
        "bg-white  px-4 sm:flex-row",
        {
          "py-[18px]": tempAgentId,
          "py-2": !tempAgentId,
        }
      )}
    >
      <div
        className={cx("w-[100px]", "font-bold lg:w-[129px]", {
          invisible: step === 1 || purchaseState,
        })}
      >
        <Button
          text="Back"
          className="h-[45px] text-[20px] font-normal leading-[24.2px] sm:ml-2"
          action={() => {
            if (!setStep) return;
            setStep((current) => {
              if (current === 1) return current;
              else return current - 1;
            });
          }}
        />
      </div>
      {!tempAgentId && (
        <div className="justify-center text-end text-xs text-black sm:pr-2 sm:text-base">
          Agent Support | AppTechSupport@NCD.com | 1-844-284-4944
        </div>
      )}
      {/* <div className="font-montserrat text-navyBlue font-bold text-lg lg:text-3xl text-center">
        {stepsWithDescription[step - 1]}
      </div>
      <div className="flex flex-row">
        <div className="pr-2 font-montserrat text-navyBlue font-bold lg:text-3xl text-center pl-3 self-center justify-self-center mt-5">
          {percentage}%
        </div>
        <div className="pr-6">
          <div className="font-montserrat font-bold text-lightBlue">
            PROGRESS
          </div>
          <div className="ml-1 flex flex-start items-center relative md:w-[300px] lg:w-[400px] outline outline-4 outline-lightBlue">
            <div
              style={{ width: percentage + "%" }}
              className={cx(
                `h-[20px] lg:h-[40px] bg-orange transition-all duration-300`
              )}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ProgressBar;
