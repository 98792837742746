import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import Button from "../../components/Button";
import { Header } from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import Agent from "../../models/Agent";

type AgentLoginProps = {
  agent: Agent;
};

const AgentLogin: FC<AgentLoginProps> = ({ agent }: AgentLoginProps) => {
  const [agentID, setAgentID] = useState<number>();
  const [invalidAgentID, setInvalidAgentID] = useState(false);
  const [invalidAnimation, setInvalidAnimation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (invalidAgentID === true) {
      setInvalidAgentID(false);
      setInvalidAnimation(true);
      setTimeout(() => setInvalidAnimation(false), 10000);
    }
  }, [invalidAgentID]);

  useEffect(() => {
    document
      .querySelector("input")
      ?.addEventListener("keypress", function (evt) {
        if (evt.key !== "Enter") {
          if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        }
      });
  }, []);

  return (
    <div id="root relative h-screen" className="relative">
      <div className="absolute z-0 flex h-screen w-full flex-col items-center bg-gradient-to-r from-[#003764] to-[#53BFE7] bg-cover bg-center bg-no-repeat">
        <Header />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            agent.id = agentID;
            agent.loginAgent().then((data) => {
              const agentResponseData = JSON.parse(data?.data)
              if (data?.status !== 200 || agentResponseData.ACTIVE !== 1) setInvalidAgentID(true);
              else {
                setInvalidAgentID(false);
                navigate(`/${agentID}`);
              }
            });
          }}
          className="relative z-50 flex h-[60%] flex-col items-center justify-center self-center justify-self-center px-2 font-inter sm:h-[70%]"
        >
          <div>
            <div className="rounded-3xl border-4 border-black bg-white p-4 xs:p-8 sm:px-[75px] sm:py-[51px]">
              <div
                className={cx(
                  "text-lg leading-[24.2px]",
                  "sm:text-xl",
                  "text-left font-bold text-black"
                )}
              >
                Please enter your Agent ID
              </div>
              <div className="mt-[15px] flex flex-col items-start justify-start text-navyBlue">
                <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
                  <div className="flex flex-col">
                    <input
                      className={cx(
                        "h-[40px] w-full md:max-w-[215px]",
                        "appearance-none rounded-lg",
                        "border border-solid border-black bg-backgroundSolidGrey",
                        "pl-3 text-xl leading-[24.2px] focus:outline-none"
                      )}
                      type="number"
                      maxLength={6}
                      value={agentID}
                      onChange={(event) => {
                        setAgentID(
                          parseInt(
                            event.target.value.slice(0, 6).replace(/\D/g, "")
                          )
                        );
                        setInvalidAgentID(false);
                      }}
                    />
                    {invalidAnimation && (
                      <p className="mt-2 text-red md:hidden">
                        Please enter a valid agent id
                      </p>
                    )}
                  </div>
                  <div
                    className={cx(
                      "h-[40px] w-full text-xl leading-[24.2px]",
                      "md:h-[40px] md:w-[121.38px] md:max-w-[145px]"
                    )}
                  >
                    <Button text={"Submit"} submit mainPath />
                  </div>
                </div>
                <p
                  className="mt-[15px] self-start text-start text-base font-medium leading-[19.36px] text-black"
                >
                  Can't remember your Agent ID?
                </p>

                <a
                  href="tel:1-844-284-4944"
                  className="mt-[5px] cursor-pointer self-start text-lg font-bold leading-[19.36px] text-celadonBlue"
                >
                  Call 1-844-284-4944
                </a>

                <div
                  className={`ant-message hidden md:block ${
                    invalidAnimation ? "visible animate-antMoveIn" : "invisible"
                  }`}
                >
                  <div>
                    <div className="ant-message-notice">
                      <div className="ant-message-notice-content">
                        <div className="ant-message-custom-content ant-message-error flex flex-row">
                          <span
                            role="img"
                            aria-label="close-circle"
                            className="anticon anticon-close-circle"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="close-circle"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                            </svg>
                          </span>
                          <span>Please enter a valid agent id</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="fixed bottom-0 mt-2 ml-auto mr-auto w-full bg-orange pt-1">
          <ProgressBar step={1} />
        </div>
      </div>
    </div>
  );
};

export default AgentLogin;
