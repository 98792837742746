import React, { FC, useEffect, useState } from "react";
import cx from "classnames";
import Button from "../../components/Button";
import Agent from "../../models/Agent";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";

interface MemberState {
  memberId: string;
  firstName: string;
  lastName: string;
}

type AgreementsProps = {
  agent: Agent;
};

const Completed: FC<AgreementsProps> = ({
  agent
}: AgreementsProps) => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const location = useLocation();
  // Access the state passed via navigate()
  const memberData = location.state as MemberState;

  const [tempAgentId, setTempAgentId] = useState("");

  // TODO: Find a way to get agent id not from url, if keeping this add checks. Temp code for now
  const getAgentId = () => {
    const getUrl = window.location.href;
    setTempAgentId(getUrl.slice(-6));
  };

  useEffect(() => {
    getAgentId();
  });

  return (
    <div
      className={cx(
        "h-full w-full",
        "bg-gradient-to-r from-[#003764] to-[#53BFE7]"
      )}
    >
      <Header agentState={agent} />
      <div className="border-b-2 border-b-orange"></div>
      <div className={cx("px-2 pt-4 pb-48 sm:pb-0")}>
        <div className="flex h-screen flex-row justify-center gap-4">
          <div className="flex basis-full flex-col gap-3 lg:basis-2/4">
            <div className="flex h-[250px] w-full flex-col items-center justify-center self-center rounded-3xl border-4 border-black bg-white p-4 text-center">
              <img
                src="/ncd_logo.svg"
                className="h-24 xs:h-32"
                alt="NCD Logo"
              />
              <div className="text-xl font-medium text-black">
                Welcome to NCD!
              </div>
              <div className="text-sm text-textGrey">
                Your Application has been received
              </div>
              {tempAgentId !== "660555" ? (
                <div className="mt-2 h-[40px] w-[230px] font-bold">
                  <Button
                    text="Start a new application"
                    className="startNewApplication"
                    action={() => {
                      navigate(`/${agentId}`);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="rounded-3xl border-4 border-black bg-white p-4">
              <div className="flex">
                <div className="my-2">
                  <p className="font-semibold">
                    Member ID:{" "}
                    <span className="font-normal">{memberData.memberId}</span>
                  </p>
                  <p className="font-semibold">
                    Primary Member:{" "}
                    <span className="font-normal">
                      {memberData.firstName} {memberData.lastName}
                    </span>
                  </p>
                </div>
                {agentId === "660555" && (
                  <div
                    className="ml-auto h-5 cursor-pointer pl-4 text-lightBlue"
                    role="button"
                    onClick={() => window.print()}
                  >
                    Print This Page
                  </div>
                )}
              </div>
              <div className="flex max-w-3xl">
                <p className="font-semibold">New Members:</p>
              </div>
              <p className="my-2">
                Please be on the lookout for your Enrollment Confirmation
                Letter.
              </p>
              <div className="my-2">
                <p>
                  If you submitted an email address, you'll be getting an email
                  from{" "}
                </p>
                <a className="text-lightBlue" href="mailto:membercare@NCD.com">
                  Metlife.Welcome@NCD.com
                </a>
              </div>
              <p className="my-2">
                If you did not submit an email, you'll be receiving your New
                Member Enrollment Kit in the mail very soon.
              </p>
              <p className="my-2">
                For immediate needs or specific questions, you can contact NCD
                Member Care department in the following ways:
              </p>
              <div className="my-2">
                <p>
                  Phone:{" "}
                  <a href="tel:1-800-485-3855" className="text-lightBlue">
                    1-800-485-3855
                  </a>
                </p>
                <p>
                  Email:{" "}
                  <a
                    className="text-lightBlue"
                    href="mailto:Metlife.MemberCare@ncd.com"
                  >
                    Metlife.MemberCare@ncd.com
                  </a>
                </p>
              </div>
              {agentId !== "660555" ? (
                <>
                  <p className="my-2 font-semibold">Agents:</p>
                  <p className="my-2">
                    If you need assistance with this application or have
                    immediate questions, please contact{" "}
                    <a
                      className="text-lightBlue"
                      href="mailto:AppTechSupport@NCD.com"
                    >
                      AppTechSupport@NCD.com
                    </a>
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed bottom-0 z-30 ml-auto mr-auto w-full bg-orange pt-1`}
      >
        <ProgressBar
          step={1}
          agent={agent}
        // stepsWithDescription={stepsNames}
        />
      </div>
    </div>
  );
};

export default Completed;
