import React, { useState, useEffect } from "react";
import cx from "classnames";
import Button from "../Button";
import Payment from "../../models/Payment";
import { z, ZodType } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type FormData = {
  cardNumber: string;
  month: string;
  year: string;
  ccv: string;
};

type CreditFormProps = {
  payment: Payment;
  setPayment: React.Dispatch<React.SetStateAction<Payment>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

function CreditForm({ payment, setPayment, setStep }: CreditFormProps) {
  const [creditCardNumber, setCreditCardNumber] = useState<string>("");
  const [ccv, setCcv] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [showExpiryError, setShowExpiryError] = useState<boolean>(false);

  const [ccvLimit, setCCVLimit] = useState<number>(3);
  const [cardNumberLimit, setCardNumberLimit] = useState<number>(16);

  const today = new Date();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const currentYear = String(today.getFullYear());

  /** React Hook Forms and Zod Implementation  */
  const schema: ZodType<FormData> = z.object({
    cardNumber: z
      .string()
      .length(cardNumberLimit, {
        message: `Card number must be at least ${cardNumberLimit} digits long!`,
      })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
    month: z
      .string()
      .length(2, { message: "Month must be 2 digits long!" })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
    year: z
      .string()
      .length(2, { message: "Year must be 2 digits long!" })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
    ccv: z
      .string()
      .length(ccvLimit, { message: `CCV must be ${ccvLimit} digits long!` })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
  });

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (
      (getValues("cardNumber") &&
        getValues("cardNumber").substring(0, 2) === "34") ||
      getValues("cardNumber").substring(0, 2) === "37"
    ) {
      setCCVLimit(4);
      setCardNumberLimit(15);
      return;
    }
    setCCVLimit(3);
    setCardNumberLimit(16);
  }, [getValues("cardNumber")]);

  const submitInfo = (data: FormData) => {
    if (currentYear.slice(2, 4) > year) {
      setShowExpiryError(true);
      return;
    } else if (year === currentYear.slice(2, 4) && currentMonth > month) {
      setShowExpiryError(true);
      return;
    }

    setShowExpiryError(false);
    setStep((current) => current + 1);
  };

  return (
    <div className="flex flex-col gap-4 p-2">
      <div className="text-xl font-medium text-navyBlue">
        Credit Card Information
      </div>
      <form
        className={cx(
          "flex flex-col flex-wrap",
          "gap-2",
          "xl:flex-row xl:flex-nowrap xl:gap-5"
        )}
        onSubmit={handleSubmit(submitInfo)}
      >
        <div className="flex flex-col">
          <label className="flex flex-col font-normal">
            <span className="pb-3 text-xl font-bold text-black">
              Card Number
            </span>
            <input
              {...register("cardNumber")}
              className={cx("form-input border-celadonBlue xl:w-[241px]", {
                "border-red": errors.cardNumber,
              })}
              type="number"
              inputMode="numeric"
              pattern={"[0-9s]{13,19}"}
              autoComplete="cc-number"
              placeholder="xxxx xxxx xxxx xxxx"
              value={creditCardNumber}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onKeyDown={(event) => {
                ["e", "E", "+", "-", "."].includes(event.key) &&
                  event.preventDefault();
                if (
                  event.code === "Slash" ||
                  event.code === "Comma" ||
                  event.code === "Period" ||
                  event.code === "NumpadAdd" ||
                  event.code === "NumpadSubtract"
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                if (
                  event?.target?.value.substring(0, 2) === "34" ||
                  event?.target?.value.substring(0, 2) === "37"
                ) {
                  if (event?.target?.value.length > 15) return;
                } else {
                  if (event?.target?.value.length > 16) return;
                }
                setCreditCardNumber(event?.target?.value);
                setPayment((current) => {
                  current.ccNumber = event?.target?.value;
                  return current;
                });
              }}
            />
          </label>
          {errors.cardNumber && (
            <span className="error">{errors.cardNumber.message}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label className="flex flex-col font-normal">
            <span className="pb-3 text-xl font-bold text-black">Month</span>
            <input
              {...register("month")}
              className={cx("form-input border-celadonBlue xl:w-[102px]", {
                "border-red": errors.month,
              })}
              placeholder="xx"
              type={"number"}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              value={month}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={(event) => {
                if (event?.target?.value.length > 2) return;

                if (
                  event?.target?.value.length < 2 &&
                  parseInt(event?.target?.value[0]) > 1
                ) {
                  return;
                }
                if (
                  event?.target?.value.length === 2 &&
                  parseInt(event?.target?.value[0]) === 1 &&
                  parseInt(event?.target?.value[1]) > 2
                ) {
                  return;
                }
                setMonth(event?.target?.value);
                setPayment((current) => {
                  current.ccExpMonth = event?.target?.value;
                  return current;
                });
              }}
            />
          </label>
          {errors.month && (
            <span className="error">{errors.month.message}</span>
          )}
        </div>

        <div className="flex flex-col">
          <label className="flex flex-col font-normal">
            <span className="pb-3 text-xl font-bold text-black">Year</span>
            <input
              {...register("year")}
              className={cx("form-input border-celadonBlue xl:w-[170px]", {
                "border-red": errors.year,
              })}
              placeholder="xx"
              type={"number"}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              value={year}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={(event) => {
                if (event?.target?.value.length > 2) return;
                setYear(event?.target?.value);
                setPayment((current) => {
                  current.ccExpYear = event?.target?.value;
                  return current;
                });
              }}
            />
          </label>
          {errors.year && <span className="error">{errors.year.message}</span>}
        </div>

        <div className="flex flex-col">
          <label className="flex flex-col font-normal">
            <span className="pb-3 text-xl font-bold text-black">CCV</span>
            <input
              {...register("ccv")}
              className={cx("form-input border-celadonBlue xl:w-[170px]", {
                "border-red": errors.ccv,
              })}
              placeholder={
                creditCardNumber.substring(0, 2) === "34" ||
                creditCardNumber.substring(0, 2) === "37"
                  ? "xxxx"
                  : "xxx"
              }
              type={"number"}
              value={ccv}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={(event) => {
                if (payment?.ccNumber) {
                  if (
                    creditCardNumber.substring(0, 2) === "34" ||
                    creditCardNumber.substring(0, 2) === "37"
                  ) {
                    if (event?.target?.value.length > 4) {
                      return;
                    }
                  } else {
                    if (event?.target?.value.length > 3) return;
                  }
                }
                setCcv(event?.target?.value);
                setPayment((current) => {
                  current.ccSecurityCode = event?.target?.value;
                  return current;
                });
              }}
            />
          </label>
          {errors.ccv && <span className="error">{errors.ccv.message}</span>}
        </div>

        <div
          className={cx(
            "mt-auto h-[46px] w-[241px]",
            "font-inter text-2xl font-normal"
          )}
        >
          <Button
            text="Submit"
            className="submitCreditCardForm"
            submit
            mainPath
          />
        </div>
      </form>
      {showExpiryError && <span className="error">This card is expired!</span>}
    </div>
  );
}

export default CreditForm;
