import React, { useState } from "react";
import cx from "classnames";
import { z, ZodType } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "../Button";
import Payment from "../../models/Payment";

type FormData = {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
};

type ACHFormProps = {
  payment: Payment;
  setPayment: React.Dispatch<React.SetStateAction<Payment>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

function ACHForm({ payment, setPayment, setStep }: ACHFormProps) {
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  /** React Hook Forms and Zod Implementation  */
  const schema: ZodType<FormData> = z.object({
    routingNumber: z
      .string()
      .length(9, { message: "Routing number must be 9 digits long" })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
    accountNumber: z
      .string()
      .min(5, { message: "Account number must between 5 and 17 digits long" })
      .refine((value) => /[0-9]/.test(value), "Must only be numbers"),
    accountType: z.enum(["C", "S"], {
      errorMap: () => ({ message: "Please select an Account Type" }),
    }),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const submitInfo = (data: FormData) => {
    setStep((current) => current + 1);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitInfo)}>
        <div className={cx("flex flex-col gap-4", "pt-4 pb-2 pr-2")}>
          <div className="text-xl font-medium text-navyBlue">
            Bank Account Information
          </div>
          <div
            className={cx(
              "flex flex-col gap-2",
              "xl:flex-row xl:flex-wrap xl:gap-5"
            )}
          >
            <div className="flex flex-col">
              <label className="flex flex-col font-normal">
                <span className="pb-3 text-xl font-bold text-black">
                  Routing Number
                </span>
                <input
                  {...register("routingNumber")}
                  className={cx("form-input border-celadonBlue xl:w-[241px]", {
                    "border-red": errors.routingNumber,
                  })}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder="Enter number"
                  type="number"
                  pattern={"[0-9s]{9}"}
                  maxLength={9}
                  value={routingNumber}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(event) => {
                    if (event?.target?.value.length > 9) return;
                    setRoutingNumber(event?.target?.value);
                    setPayment({ ...payment, achRouting: event.target.value });
                  }}
                />
              </label>
              {errors.routingNumber && (
                <span className="error w-full xl:w-48">
                  {errors.routingNumber.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <label className="flex flex-col font-normal">
                <span className="pb-3 text-xl font-bold text-black">
                  Account Number
                </span>
                <input
                  {...register("accountNumber")}
                  className={cx("form-input border-celadonBlue xl:w-[241px]", {
                    "border-red": errors.accountNumber,
                  })}
                  placeholder="Enter number"
                  type="number"
                  pattern={"[0-9s]{5,17}"}
                  maxLength={17}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={accountNumber}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(event) => {
                    if (event?.target?.value.length > 17) return;
                    setAccountNumber(event?.target?.value);
                    setPayment({ ...payment, achAccount: event.target.value });
                  }}
                />
              </label>
              {errors.accountNumber && (
                <span className="error w-full xl:w-64">
                  {errors.accountNumber.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <label className="flex flex-col font-normal">
                <span className="pb-3 text-xl font-bold text-black">
                  Account Type
                </span>
                <select
                  {...register("accountType")}
                  className={cx(
                    "form-input border-celadonBlue bg-white xl:w-[241px]",
                    {
                      "border-red": errors.accountType,
                    }
                  )}
                  onChange={(event) => {
                    setPayment({
                      ...payment,
                      achType: event.target.value,
                    });
                  }}
                  defaultValue={""}
                >
                  <option value={""} className="" hidden>
                    Select type
                  </option>
                  <option value={"C"}>Checking</option>
                  <option value={"S"}>Savings</option>
                </select>
              </label>
              {errors.accountType && (
                <span className="error">{errors.accountType.message}</span>
              )}
            </div>

            <div
              className={cx(
                "mt-auto h-[46px] w-[200px]",
                "font-inter text-2xl font-normal"
              )}
            >
              <Button text="Submit" className="submitACHForm" submit mainPath />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ACHForm;
