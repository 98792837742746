import { create } from "zustand";

interface ApplicationState {
  applicationDate: number;
  setApplicationDate: (date: number) => void;
}

const useApplicationStore = create<ApplicationState>()((set) => ({
  applicationDate: new Date().getMonth(),
  setApplicationDate: (date) => set(() => ({ applicationDate: date })),
}));

export default useApplicationStore;
